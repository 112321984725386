<template>
  <div v-if="word" class="product">
    <div class="stk animate__animated animate__flipInX">
      <!-- <div class="stkBg"></div> -->
      <img src="./../assets/whatsApp2.png" alt="" />
      <img class="img2" src="./../assets/inboxBg.png" alt="" />
      <div class="font_detail">
        <h2>{{ word.menu.titleTop13[language] }}</h2>
        <p class="jianjie">
          {{ word.menu.jieshao[language] }}
        </p>

        <div class="jiage" @click="goToPrice">
          {{ word.menu.jiage[language] }}
        </div>
      </div>
      <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
    <div style="padding-bottom: 80px">
      <div id="title" class="title">Inbox</div>
      <div class="smallTitle">
       {{
          word.product.product14[language]
        }}
      </div>

      <div class="homeMiddle" id="project">
        <div id="homeMiddleleft" :class="{height: language== 'EN'}" class="homeMiddle_left show">
          <img src="./../assets/inboxMiddle.jpg" alt="" />
        </div>
        <div id="homeMiddleright" class="homeMiddle_right show">
          <h2 class="homeMiddle_right_title">
            {{ word.menu.projectList4[language] }}
          </h2>
          <p class="title-p">
            {{ word.menu.projectListDesc13[language] }}
          </p>
          <div class="homeMiddle_list">
            <div class="homeMiddle_list_img">
              <img src="./../assets/whatsAppKeFu.png" alt="" />
              <h3 class="top">{{ word.product.marketing29[language] }}</h3>
            </div>

            <div class="bottom">{{ word.menu.marketDesc33[language] }}</div>
          </div>
          <div class="homeMiddle_list">
            <div class="homeMiddle_list_img">
              <img src="./../assets/shouhou.png" alt="" />
              <h3 class="top">{{ word.product.marketing30[language] }}</h3>
            </div>

            <div class="bottom">{{ word.menu.marketDesc34[language] }}</div>
          </div>
          <div class="homeMiddle_list">
            <div class="homeMiddle_list_img">
              <img src="./../assets/tuiguang.png" alt="" />
              <h3 class="top">{{ word.product.marketing31[language] }}</h3>
            </div>

            <div class="bottom">{{ word.menu.marketDesc35[language] }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="homeFooter" v-if="word">
      <div v-if="!mode" id="special" class="special">
        <div
          id="special-point"
          class="special-point"
          :class="language == 'EN' ? 'english' : ''"
        >
          <div id="block" class="special-item">
            <div class="special-icon">
              <img src="./../assets/fmt.png" alt="" />
            </div>
           
            <div class="special-item-title">
              <span class="fontStyle"><span id="font1"></span>+</span>
              {{ word.special.special63[language] }}
            </div>
            <div>
              {{ word.special.special64[language] }}
            </div>
          </div>
          <div id="block" class="special-item">
            <div class="special-icon">
              <img src="./../assets/jiangdi.png" alt="" />
            </div>
            <div class="special-item-title">
              <span class="fontStyle"><span id="font2"></span>%</span>
              {{ word.special.special65[language] }}
            </div>
            <div>
              {{ word.special.special66[language] }}
            </div>
          </div>
          <div id="block" class="special-item animate__animated">
            <div class="special-icon">
              <img src="./../assets/yuedu.png" alt="" />
            </div>
            <div class="special-item-title">
              <span class="fontStyle"><span id="font3"></span>%</span>
              {{ word.special.special67[language] }}
            </div>
            <div>
              {{ word.special.special68[language] }}
              
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div style="position: relative">
      <div style="height: 300px"></div>

      <div class="footer">
        <div
          style="
            color: #fff;
            text-align: center;
            font-size: 30px;
            margin-top: 150px;
          "
        >
          {{ word.product.tip[language] }}
        </div>
        <div @click="toPrice" class="look-price">
          {{ word.product.buttonText[language] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
import "animate.css";
import word from "../assets/json/language.json";
export default {
  name: "Product",
  components: {},
  data() {
    return {
      language: "",
      word: word,
      isScrool: false,
    };
  },
  created() {
    this.language = localStorage.getItem("language");
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
    const element1 = document.getElementById("title");
    const element2 = document.getElementById("homeMiddleleft");
    const element3 = document.getElementById("homeMiddleright");
    const element4 = document.getElementById("homeFooter");
    window.addEventListener("scroll", (res) => {
      const scrollTop = document.documentElement.scrollTop; // 查看滑动的距离
      const top1 = element1?.offsetTop;
      if (scrollTop > top1 - 100) {
        element2?.classList.remove("show");
        element2?.classList.add("animate__animated");
        element2?.classList.add("animate__fadeInLeft");
        setTimeout(() => {
          element3?.classList.remove("show");
          element3?.classList.add("animate__animated");
          element3?.classList.add("animate__fadeInRight");
        }, 500);
        const top2 = element4?.offsetTop;
        if (scrollTop > top2 - 300) {
          if (!this.isScrool) {
            const element77 = document.getElementById("font1");
            let dom = new CountUp(element77, 8);
            dom.start();
            const element88 = document.getElementById("font2");
            let dom1 = new CountUp(element88, 70);
            dom1.start();
            const element99 = document.getElementById("font3");
            let dom2 = new CountUp(element99, 75);
            dom2.start();
            this.isScrool = true;
          }
        }
      }
    });
  },
  methods: {
    goToPrice() {
      this.$router.push("/price");
    },
    toPrice() {
      this.$router.push({ path: "/price" });
    },
  },
};
</script>
<style lang="scss" scoped>

.img2{
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 400px !important;
  height: 400px !important;
}
.fontStyle {
  background: -webkit-linear-gradient(0deg, #6743e5, #b540f4);
  background-clip: border-box;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
}
.special {
  .title_desc {
    color: #353535;
    text-align: center;
    margin-bottom: 30px;
  }
  .special-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &.english {
      .special-item {
        font-size: 13px;
        .special-item-title {
          font-size: 16px;
          margin: 14px 0;
        }
      }
    }
    .special-item {
      background-color: #fff;
      border-radius: 16px;
      padding: 16px;
      height: 250px;
      width: 25%;
      margin: 0 1% 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 14px;
      color: #2e2e2e;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      &:hover {
        transform: scale(1.05) !important;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      }
      .special-icon {
        padding-top: 20px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        img {
          width: 50px;
          height: 50px;
        }
        i {
          font-size: 48px;
        }
      }
      .special-item-title {
        margin: 20px 0;
        font-size: 26px;
      }
    }
  }
}
.show {
  opacity: 0 !important;
  transform: translateY(500px) !important;
}
.stk {
  width: 100%;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }

  .stkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #424267 !important;
    opacity: 0.5 !important;
    z-index: 2;
  }

  .font_detail {
    width: 65%;
    font-size: 30px !important;
    color: #fff !important;
    line-height: 1.5em !important;
    font-weight: 800 !important;
    position: absolute;
    bottom: 130px;
    z-index: 33;
    left: 40px;
  }

  .jianjie {
    line-height: 1.5em !important;
    font-size: 18px;
    width: 60%;
    color: #fff !important;
  }

  .jiage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    width: 150px;
    border: 2px solid #fff;
    cursor: pointer;

    &:hover {
      background: #108ccf;
    }
  }
}
.title {
  font-weight: 800 !important;
  padding: 100px 0 10px 0;
  font-size: 40px;
  text-align: center;
}

.footer {
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  bottom: -120px;
  height: 450px;
  width: 90%;
  border-radius: 20px;
  background: url("./../assets/lianxi.jpg");
  background-size: 100% 100%;
}

.smallTitle {
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #4d5d6d;

  .image {
    vertical-align: middle;
  }
}

.height {
  img {
    height: 810px !important;
  }
}

.homeMiddle {
  padding-top: 30px;
  display: flex;
  &_left {
    padding: 100px 50px 0 50px;
    width: 50%;
    img {
      border-radius: 10px;
      width: 100%;
      height: 710px;
    }
  }
  &_right {
    flex: 1;
    box-sizing: border-box;
    padding-right: 30px;

    .homeMiddle_right_title {
      font-size: 45px;
      line-height: 10px;
      font-weight: 800;
      padding-top: 80px;
    }

    .title-p {
      color: #4d5d6d;
    }
    .homeMiddle_right_box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .homeMiddle_right_list {
      color: #4d5d6d;
      display: inline-block;
      margin-top: 40px;
      img {
        width: 19px;
        height: 19px;
        vertical-align: middle;
      }
    }

    .homeMiddle_list {
      background-color: #f3f5f7 !important;
      border-radius: 10px !important;
      overflow: hidden !important;
      padding-top: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 30px !important;
      padding-left: 30px !important;
      cursor: pointer;
      .homeMiddle_list_img {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .top {
        margin-left: 10px;
        font-size: 18px;
        font-weight: 700;
      }

      &:hover {
        background: #fff !important;
        box-shadow: 0px 0px 24px -10px #000 !important;
      }
    }

    .bottom {
      color: #4d5d6d;
    }
  }
}
.product-title {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 100px;
  background-color: #6c63ff;
  img {
    display: block;
    height: 300px;
    text-align: center;
  }
  .banner-text {
    position: absolute;
    left: 28%;
    top: 40%;
    font-size: 46px;
    color: #fff;
  }
}
.product-info {
  .product-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 400px;
      flex-shrink: 0;
    }
    > div {
      width: 380px;
      line-height: 1.8;
      padding: 0 50px;
      color: #888;
      .product-subtitle {
        font-size: 30px;
        margin-bottom: 10px;
        color: #353535;
      }
    }
  }
}
</style>
